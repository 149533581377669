.add-kg,
.bw-history {
  width: 80vw;
  margin: 0 auto;
}

.bw-history {
  text-align: center;
}

.first-weight h3 {
  position: absolute;
  z-index: 999;
  color: white;
  left: 0;
  width: 100%;
  text-align: center;
}
