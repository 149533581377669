@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600;900&display=swap");
.App {
  margin: 0;
}
:root {
  --gradient: linear-gradient(125.26deg, #e77200 0%, #e82500 100%);
  --radius: 10px;
  --width: 60%;
  --min-width: 250px;
  --max-width: 600px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans", sans-serif;
  color: #303030;
}

h1 {
  font-size: 2em;
  line-height: 50px;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;
}

.sub {
  font-size: 30px;
  font-weight: 300;
}

.gradientbg {
  background: var(--gradient);
  color: white;
}

.gradient {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

#container {
  width: var(--width);
  min-width: var(--min-width);
  margin: auto;
  padding: 10vh 0;
}

a {
  font-weight: 900;
  color: inherit;
  text-decoration: none;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.close a {
  font-size: 30px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  background: var(--gradient);
  height: 30vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  position: absolute;
  top: 0;
  width: 90vw;
  z-index: 9;
  left: 0;
  margin-left: 5vw;
}

.top img {
  height: 40px;
}

.burger span {
  height: 3px;
  background: white;
  width: 30px;
  margin: 5px 0;
  display: block;
}

.circleinfo {
  width: 40vw;
  height: 40vw;
  max-width: 300px;
  max-height: 300px;
  background: #f9f9f9;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -15vh auto 40px auto;
  border-radius: 50%;
  border: 2px solid #e77200;
  box-shadow: inset 5px 5px 7px 10px #ccc, 0 0 0 20px #f9f9f9;
  filter: drop-shadow(0 0 8px #333);
}

.circleinfo .ratio {
  font-weight: 600;
  font-size: 3em;
}

.circleinfo .date {
  font-size: 1em;
  text-align: center;
}

.fact {
  padding: 50px 20vw;
  margin: 30px 0;
}

button {
  border: 0;
  border-radius: var(--radius);
  padding: 20px 5px;
  margin: 20px 0;
  width: 100%;
  font-weight: 700;
}
button p {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

/* nav */
#navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  z-index: 19;
  height: 100vh;
  background: #eee;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;
}

#main {
  transition: all 0.2s;
  background: white;
}

#navigation li,
#navigation ul {
  list-style: none;
  padding-block-start: 0;
  padding-inline-start: 0;
  margin-inline-start: 0;
}

.navmenu ul {
  display: flex;
  flex-direction: column;
}

.navmenu ul li {
  margin-top: 2vh;
}
.navmenu ul li img {
  padding-right: 20px;
}

.submenu li {
  margin: 15px 0;
}

.navprofile {
  display: flex;
  align-items: center;
  height: 10vh;
}

.navprofile .profilepic {
  padding: 5px;
  min-width: 50px !important;
  min-height: 50px !important;
  height: 50px;
  width: 50px;
  filter: drop-shadow(1px 1px 1px #ccc);
}

.profilename {
  font-size: 12px;
}

.nicename {
  font-weight: 700;
  font-size: 14px;
}

.profilepic {
  width: 10vw;
  height: 10vw;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
}

#addnew {
  width: 10vw;
  height: 10vw;
  max-width: 80px;
  max-height: 80px;
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 60px;
  border-radius: 50%;
  filter: drop-shadow(1px 1px 1px #ccc);
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
}

#addnew p {
  font-weight: 300;
}

li {
  list-style: none;
}

.header.small {
  height: 100px;
}

.center{
  text-align: center;
}