.formrow {
  display: flex;
}

.recordform {
  width: var(--width);
  min-width: var(--min-width);
  margin: 10px auto;
}

.recordform div {
  margin-top: 10px;
}

.recordform label {
  margin: 5px 10px;
  font-size: 0.8em;
}

.recordform select {
  border-radius: 5px;
  height: 49px;
  border: 1px solid #e5e6e6;
  background: #f9f9f9;
  width: 100%;
  margin: 10px 0;
}

.upload {
  width: 40vw;
  height: 40vw;
  max-width: 250px;
  max-height: 250px;
  background: #f9f9f9;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: var(--radius);
  box-shadow: 0 0 0 20px #f9f9f9;
  filter: drop-shadow(0 0 8px #333);
}

.uploader {
  width: 80%;
  min-height: 100px;
  background: #fff;
  border: 1px dotted #e77200;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: var(--radius);
}

.deadlift {
  background: var(--gradient), url("./images/deadlift.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.squat {
  background: var(--gradient), url("./images/squat.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.ohp {
  background: var(--gradient), url("./images/ohp.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.bench {
  background: var(--gradient), url("./images/bench.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.linebutton {
  text-align: left;
  width: 80%;
  margin: 10px 10%;
  padding: 20px;
  color: white;
}

.linebutton h2 {
  width: 100%;
  margin: 5px 0;
  padding: 0;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e77200;
}

.single-multi-lift {
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifiedselect {
  display: block;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e77200;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  height: 35px;
  width: 60px;
  margin-top: 5px;
}

.slider.round:before {
  border-radius: 50%;
}
