.white {
  color: #fff;
}

.select-activity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.liftcard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: var(--radius);
  max-width: var(--max-width);
  min-width: var(--min-width);
  margin: 20px auto;
  border: 0.5px solid #f9f9f9;
  height: 152px;
  width: 284px;
  border: 1px solid #e5e6e6;
  box-sizing: border-box;
  box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.5s;
}

.liftcard img {
  width: 80px;
  height: 80px;
}

.cardleft {
  padding: 20px;
  font-size: 12px;
  font-weight: 300;
}

.liftcard:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
}
