.socialbuttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 60%;
  min-width: 250px;
}
.social:nth-child(1) {
  background: #3b5998;
  height: 50px;
}

.social:nth-child(2) {
  border: 1px solid #e8e8e8;
  background: white;
  height: 50px;
}

.social:nth-child(3) {
  background: #1da1f2;
  height: 50px;
}

.social img {
  height: 20px;
  width: auto;
}

.sixty {
  width: 60%;
  min-width: 250px;
}

.large {
  font-size: 3em;
}
