.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .button-links {
  display: block;
  padding: 20px;
  border: solid;
  margin-top: 10px;
}

.exercisecard {
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  margin: 10px auto;
  border-radius: var(--radius);
  filter: drop-shadow(0 0 3px #ccc);
  border: 1px solid #ccc;
  background: white;
  padding: 30px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.exercisecard .sub {
  font-size: 1em;
}

.exercisecard .cardratio {
  font-size: 3em;
}

.exercisecard .cardweight {
  font-weight: 900;
  margin: 5px 0;
}

.exercisecard .carddate {
  font-weight: 300;
  font-size: 0.8em;
}

.ratios {
  font-size: 0.8em;
  align-self: center;
  font-weight: 700;
  text-align: center;
}

.ratios .ratio {
  font-size: 2.4em;
}

.leaderboardcontainer {
  width: var(--width);
  min-width: var(--min-width);
  margin: auto;
}
.leaderboardheader {
  display: flex;
  justify-content: space-between;
}

.user {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 15px;
  margin: auto;
}

.user .pos {
  font-weight: 900;
}

.view {
  margin: 30px 0;
  width: 100%;
  text-align: center;
}

select.exerciseselect {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url(./images/icons/Arrows.svg); /* Add custom arrow */
  background-position: right;
  background-repeat: no-repeat;
  padding: 20px;
  font-weight: 900;
  font-size: 1em;
  text-align: right;
}

select.exerciseselect:focus {
  outline: none;
}

.leadboardranking {
  display: flex;
  width: var(--width);
  min-width: var(--min-width);
  margin: 0 auto;
}

.leaderboardratio {
  margin: 0 30px 0 0;
  color: white;
  font-size: 1.2em;
}

.ratioholder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  max-width: var(--max-width);
  margin: auto;
}
