.pageheader{
  color: white;
  width: var(--width);
  min-width: var(--min-width);
  margin: 0 auto;
  padding-top: 180px;
  font-size: 2em;
}

.leaderboardheader{
  display: flex;
  flex-wrap: wrap;
}

select.exerciseselect{
  padding: 10px;
}