label, input{
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
}

input{
  border-radius: 5px;
  height: 45px;
  border: 1px solid #E5E6E6;
  background: #F9F9F9;
}